<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
// import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../../date";
// import Navbar2 from "../../components/navbar2.vue";
import blogApi from '../../apis/blogApi';
// import Navbar from "../../components/navbar.vue";
// import Navbar3 from "../../components/navbar3.vue";
import Navbar from "@/views/new-view/components/navbar";
import Footer from "@/views/new-view/components/footerBar";

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/terms' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    // Navbar2,
    Navbar,
    // Navbar3
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    } else {

    }
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar v-else/>

    <!-- 顶部 -->
    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Terms & Condition</h4>
<!--            <p class="text-muted mx-auto mb-0">-->
<!--              If you could not find an answer to your questions,please contant our customer support-->
<!--            </p>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <strong style="font-size:22px;">
          <span style="font-size: 17px; font-weight: normal;">Last Updated: 2 July 2024</span>
        </strong>
        <br/>
        <br/>
        <p>This agreement is made between you as a user and platform IncCrypto.
          <br/><br/>
          Using the services of the site IncCrypto, you agree that you have read and accepted all the conditions described in this agreement, as well as familiarized with our Privacy Policy.
          <br/><br/>
          If you do not agree with this agreement, immediately stop using the services of the platform IncCrypto.
          <br/><br/>
          By creating your account at IncCrypto, you consent to the terms and conditions set forth in this User Agreement.
          <br/><br/>
          If you have any related questions, you can always contact our support team.</p>
        <strong style="font-size:22px;">
          Terms and Definitions&nbsp;
        </strong><br/>
        <p>1. Agreement is a list of the conditions of Users that must be accepted to continue working on the platform IncCrypto.</p>
        <p>2. Cryptocurrency - a peer-to-peer electronic currency that does not have a single centralized issuer, which is distributed between holders of such a currency.</p>
        <p>3. A personal account is a page inside the platform that is created as a result of registration and acceptance of the User Agreement.</p>
        <p>4. Privacy policy - these are the rules for the collection, storage and use of personal data of users.</p>
        <p>5. User is a natural or legal person registered in accordance with local national legislation, registered and accepted the User Agreement.</p>
        <p>6. Deposit and withdrawal of funds - an operation to transfer the user's funds from a personal account.</p>
        <p>7. Account data - personal data of the user, this is the username, password, email address that the user specified when registering on the platform IncCrypto.</p>

        <strong style="font-size:22px;">
          Terms of agreement
        </strong>
        <p>About such changes IncCrypto will notify all users by the specified mail by the user, and all updates will be displayed in the Agreement Terms themselves.</p>
        <p>If you continue to work on the platform, then you automatically accept these updates.</p>
        <p>By creating an account in IncCrypto, you confirm that you are at least 18 years old and you are an individual or legal entity, an organization that is fully authorized to accept this User Agreement between you and IncCrypto.</p>
<!--        <p>After participating in the investment, you need to pay an electricity fee of 8% of the deposit amount first. The withdrawal function will be activated thereafter.</p>-->
        <strong style="font-size:22px;">
          Prohibition of use
        </strong>
        <p>Using the services of the platform IncCrypto, you declare that you are not on any list of trade or economic sanctions, such as the List of Sanctions of the UN Security Council and the like.</p>
        <p>This Agreement cannot be excluded under the laws of the country in which the user is located.</p>

        <strong style="font-size:22px;">
          Description of the services&nbsp;
        </strong>
        <p>Users have the opportunity to register, open accounts and deposit assets in IncCrypto. Platform users have the right to request the withdrawal of their funds, taking into account the restrictions specified in the Terms of the agreement, if any.</p>
        <p>All users should be aware of all the risks associated with all possible movements of cryptocurrency rates.</p>
        <p>IncCrypto never requires transfers of funds to the wallet and never asks for your account password.</p>
        <p>Users need to be careful about promotions and any discounts that could lead to fraud and loss of funds. All notifications that come to the e-mail specified by users will never require any transaction data, personal data until a user requests it.</p>
        <p>The list of fraudulent transactions is wider, the user agrees that IncCrypto does not take responsibility for any loss of funds resulting from the situations listed above.</p>
        <p>By using the services of IncCrypto, you confirm that all information provided by IncCrypto under this Agreement is true, complete and accurate.</p>

        <strong style="font-size:22px;">
          Registration and account requirements
        </strong>
        <p>Before starting work on the platform IncCrypto all users must go through a simple registration.</p>
        <p>And also users need to accept the Terms of Use, Privacy Policy.</p>
        <p>To create an account you need to provide your real name, email address and password.</p>
        <p>At our sole discretion and depending on a number of conditions, IncCrypto may refuse to open an account.</p>

        <strong style="font-size:22px;">
          User data authentication
        </strong>
        <p>By creating an account with IncCrypto, you agree to provide personal data to verify the authenticity of your identity.</p>
        <p>These measures are necessary to detect and prevent schemes of money laundering, fraud, financing of terrorist organizations and other financial crimes.</p>
        <p>After registration, you must ensure that your information provided is complete, truthful, and you will be updated in a timely manner in the event of a change.</p>
        <p>If there is any doubt that the information you submitted is untrue or is outdated or incomplete, IncCrypto has the right to send you a notification asking you to correct or update personal data.</p>
        <p>You take full responsibility for any losses or expenses incurred at the time of use IncCrypto if you cannot contact in a timely manner and update your personal information.</p>
        <p>By this agreement, you acknowledge that you undertake to update personal information in case of any changes.</p>

        <strong style="font-size:22px;">
          Account Requirements
        </strong>
        <p>IncCrypto has the right to freeze or cancel accounts used by third parties that are not account holders.</p>
        <p>IncCrypto is not responsible for these accounts.</p>

        <strong style="font-size:22px;">
          Account security
        </strong>
        <p>IncCrypto, strives to create maximum security to preserve user funds.</p>
        <p>You are solely responsible for maintaining the security of your account at IncCrypto, for maintaining the password, and the electronic login address.</p>
        <p>Our company asks you, as account holders, to also be extremely careful and take precautions to protect your account and personal information.</p>
        <p>You will comply with the security system, authentication, processing and collection of payments or withdrawals.
          you immediately inform IncCrypto if you become aware of any unauthorized use of your account and password in IncCrypto by another person, or other violations of security rules.
          IncCrypto does not take responsibility for any losses and consequences caused by your neglect of the above account security statement.</p>

        <strong style="font-size:22px;">
          Copyright and trademarks
        </strong>
        <p>In order to properly use Intellectual Property IncCrypto, obtaining the necessary permissions is required.</p>

        <strong style="font-size:22px;">
          Recommendations for use
        </strong>
        <p>You hereby agree to abide by these points when you use the platform IncCrypto:</p>
        <p>will not shy away from paying taxes or government fees.
          all the actions you take while working on IncCrypto will meet the requirements of applicable laws, as well as charters IncCrypto.
          this agreement will not be violated, and the rules IncCrypto.
          If you have violated the above paragraphs and this has led to any legal consequences, you will independently carry out all legal paragraphs on your own behalf and protect IncCrypto from all claims and costs associated with such a violation.</p>
        <p>You will use the platform in accordance with the Privacy Policy and Terms of Use, without interfering with the normal operation of the platform IncCrypto.</p>

        <strong style="font-size:22px;">
          Limitation of liability
        </strong>
        <p>IncCrypto is not responsible for any of your losses resulting from the events described below, but not limited to:</p>
        <p>unauthorized alteration of your data by third parties.
          loss of profit, goodwill or other intangible losses.
          unauthorized use of your account.
          other losses.</p>

        <strong style="font-size:22px;">
          Recompense
        </strong>
        <p>You agree to protect IncCrypto, its current employees, directors, affiliates and agents from all kinds of losses incurred by a third party as a result of User IncCrypto actions in violation of this Agreement.</p>

        <strong style="font-size:22px;">
          Termination
        </strong>
        <p>You agree that IncCrypto has the right to stop your use of the platform, block funds if it is suspected that such accounts have violated the Terms of the Agreement, the Privacy Policy, or other applicable legislation. IncCrypto, has the right to save, use and provide transaction data of violators at the request of the legislature.</p>
        <p>The termination of the agreement is possible with such factors:</p>
        <p>by order of a court or other regulatory authority.
          if unauthorized access to the account is detected.
          if non-natural account activity is detected.
          After the account is closed and the funds are blocked, the entire balance of the user will go to pay off all costs and expenses IncCrypto.
        </p>
        <p>
          Based on the latest regulations from the relevant financial authorities in the UK, investments in cryptocurrency, mining, and other emerging industries must pay a 15% tax. This is calculated based on your current available balance. For example, if your current available balance is $1000, you must deposit $150 for tax purposes before you can withdraw $1000. Please note that since the tax is paid to the relevant financial authorities and is part of a separate bill, it cannot be deducted from your available balance. The tax must be paid through an additional deposit.
        </p>

        <strong style="font-size:22px;">
          Privacy policy
        </strong>
        <p>Privacy policy is an important clause of this agreement. IncCrypto may, if necessary, amend this clause of the agreement. All changes to the Privacy Policy, and any other clause, will be notified to all users through the mailing list indicated by their email.</p>

        <strong style="font-size:22px;">
          Compensation and Disclaimer
        </strong>
        <p>By accepting this agreement, the user agrees to reimburse all losses and expenses incurred IncCrypto if the user's actions led to such losses.</p>
        <p>IncCrypto is not responsible for errors and inaccuracies associated with the use of its services.</p>

        <strong style="font-size:22px;">
          Support service
        </strong>
        <p>If you have any questions about these terms, please contact info@inccrypto.com</p>


      </div>
    </section>



    <Footer/>
    <!-- Footer End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
